export const HCP_COOKIE_NAME = '_dokspot_disclaimer'
export const CONSENTED_COOKIE_NAME = '_dokspot_gdpr_consent'
export const SELECTED_COUNTRY_CODE_COOKIE_NAME = '_dokspot_country'
export const SELECTED_LANGUAGE_CODE_COOKIE_NAME = '_dokspot_lang'
export const DEFAULT_LANGUAGE_CODE = 'en'
export const DEFAULT_LOCALE_MANAGE_APP = 'en'
export const SUPPORTED_LANGUAGES = [
  'bg', // Bulgarian
  'cs', // Czech
  'da', // Danish
  'de', // German
  'el', // Greek
  'en', // English
  'es', // Spanish
  'et', // Estonian
  'fi', // Finnish
  'fr', // French
  'ga', // Irish
  'hr', // Croatian
  'hu', // Hungarian
  'it', // Italian
  'ja', // Japanese
  'lt', // Lithuanian
  'lv', // Latvian
  'mt', // Maltese
  'nl', // Dutch
  'no', // Norwegian
  'pl', // Polish
  'pt', // Portuguese
  'ro', // Romanian
  'ru', // Russian
  'sk', // Slovak
  'sl', // Slovenian
  'sv', // Swedish
  'zh' // Chinese
]
