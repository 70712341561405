import React, { useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import axios from 'axios'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import OrderForm from './OrderForm'

const propTypes = {
  document: PropTypes.object,
  product: PropTypes.object.isRequired,
  reference: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired,
  showConfirmation: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

const OrderFormContainer = ({
  product,
  reference,
  document,
  versionId,
  showConfirmation,
  showError,
  t
}) => {
  const [orderPosted, setOrderPosted] = useState(false)

  const schema = yup.object({
    title: yup.string(),
    firstName: yup.string().required('order_form.errors.required'),
    lastName: yup.string().required('order_form.errors.required'),
    telephone: yup.string().required('order_form.errors.required'),
    email: yup.string().required('order_form.errors.required'),
    company: yup.string().required('order_form.errors.required'),
    address1: yup.string().required('order_form.errors.required'),
    address2: yup.string(),
    postcode: yup.string().required('order_form.errors.required'),
    city: yup.string().required('order_form.errors.required'),
    country: yup.string().required('order_form.errors.required'),
    notes: yup.string()
  })

  const newOrderValues = {
    title: '',
    firstName: '',
    lastName: '',
    telephone: '',
    email: '',
    company: '',
    address1: '',
    address2: '',
    postcode: '',
    city: '',
    country: '',
    notes: ''
  }

  const orderEntity = ({
    title,
    firstName,
    lastName,
    telephone,
    email,
    company,
    address1,
    address2,
    postcode,
    city,
    country,
    notes
  }) => ({
    order: {
      customer: {
        title,
        first_name: firstName,
        last_name: lastName,
        telephone,
        email,
        organisation: company,
        address_line_1: address1,
        address_line_2: address2,
        post_code: postcode,
        city,
        country
      },
      notes,
      document_id: document.id,
      product_reference_id: reference.id
    }
  })

  const postOrder = async (formData) => {
    const orderData = orderEntity(formData)
    try {
      await axios.post('/api/orders', orderData)
      showConfirmation()
      setOrderPosted(true)
    } catch (error) {
      showError()
    }
  }

  return (
    <Container data-testid='order-form-container'>
      <Card>
        <Card.Body>
          <Card.Title
            className='mb-4'
            data-testid='order-form-container-heading'
          >
            {!orderPosted
              ? t('order_form_container.heading')
              : t('order_form_container.success_message.heading')}
          </Card.Title>
          {!orderPosted ? (
            <Formik
              validationSchema={schema}
              onSubmit={postOrder}
              initialValues={newOrderValues}
              validateOnChange={false}
            >
              {(formik) => <OrderForm {...formik} />}
            </Formik>
          ) : (
            <Card.Text>
              {t('order_form_container.success_message.paragraph')}
            </Card.Text>
          )}
        </Card.Body>
      </Card>
    </Container>
  )
}

OrderFormContainer.propTypes = propTypes

export default withTranslation()(OrderFormContainer)
