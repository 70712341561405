import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CountrySelect from './region/CountrySelect';
import CountryConfirm from './region/CountryConfirm';

import ConnectHeader from './ConnectHeader';

import HeaderTags from './HeaderTags';

import InfoModal from './InfoModal';

import { setCountry } from 'redux/actions/userLocation';
import { toBoolean } from 'components/shared/utils';
import {
  SELECTED_COUNTRY_CODE_COOKIE_NAME,
  SELECTED_LANGUAGE_CODE_COOKIE_NAME,
  DEFAULT_LANGUAGE_CODE,
} from 'components/connect/helpers/Constants';

import { Theme } from 'interfaces/Theme';

const RegionView = ({
  theme,
  userLocation,
  setCountry,
  languages,
  infoModal,
  openInfoModal,
  closeInfoModal,
}) => {

  const cookies = new Cookies();
  const countryCookie = cookies.get(SELECTED_COUNTRY_CODE_COOKIE_NAME);
  const cookieLanguage = cookies.get(SELECTED_LANGUAGE_CODE_COOKIE_NAME);

  const showConfirmation = () =>
    countryCookie !== 'null' && !toBoolean(countryCookie);

  const redirectToHomepage = () => {
    // window.location = '/'
    window.location.reload();
  };

  const { i18n } = useTranslation();

  const getLanguage = () => {
    const browserLocale = i18n.language.slice(0, 2);
    if (cookieLanguage) {
      return cookieLanguage;
    }
    if (browserLocale) {
      return browserLocale;
    }
    return DEFAULT_LANGUAGE_CODE;
  };
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const { data } = await axios.get('/api/country_location.json');
        setCountry(data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCountry();
  }, [setCountry]);

  const onConfirm = () => {
    cookies.set(SELECTED_COUNTRY_CODE_COOKIE_NAME, userLocation.country, {
      expires: Moment().add(30, 'minutes').toDate(),
      path: '/',
    });
    redirectToHomepage();
  };

  const filteredRegions = useMemo(
    () =>
      theme.visible_regions.continents.filter(
        (continent) => continent.countries.length
      ),
    [theme.visible_regions.continents]
  );

  if (!userLocation) return null;

  return (
    <div>
      <HeaderTags theme={theme} />
      <ConnectHeader
        theme={theme}
        languages={languages}
        onRegionView
        openInfoModal={openInfoModal}
      />
      <div data-testid="region-view">
        <CountrySelect
          regions={filteredRegions}
          onSelect={redirectToHomepage}
          locale={getLanguage()}
        />
        <CountryConfirm
          onConfirm={onConfirm}
          userLocation={userLocation}
          locale={getLanguage()}
          show={showConfirmation}
          theme={theme}
        />
      </div>
      <InfoModal theme={theme} show={infoModal} handleClose={closeInfoModal} />
    </div>
  );
};

const mapStateToProps = ({ userLocation }) => ({ userLocation });

RegionView.propTypes = {
  theme: Theme,
  userLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setCountry: PropTypes.func.isRequired,
  languages: PropTypes.array,
  infoModal: PropTypes.bool.isRequired,
  openInfoModal: PropTypes.func.isRequired,
  closeInfoModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setCountry })(RegionView);
