import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import axios from 'axios'

import Breadcrumbs from './../Breadcrumbs'
import DocumentViewer from './DocumentViewer'
import DocumentActions from './DocumentActions'
import DocumentSubscription from './DocumentSubscription'
import Notification from './Notification'

const propTypes = {
  document: PropTypes.object,
  reference: PropTypes.object,
  versionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subscribe: PropTypes.bool,
  t: PropTypes.func.isRequired
}

const DocumentSection = ({ document, reference, versionId, subscribe, t }) => {
  const haveNotifications = !_.isEmpty(document.notifications)
  const [notificationVisible, setNotificationVisible] = useState(
    haveNotifications
  )
  const [documentSignedURL, setDocumentSignedURL] = useState(null)

  const showNotification = () => setNotificationVisible(true)
  const hideNotification = () => setNotificationVisible(false)

  useEffect(() => {
    const getDocumentSignedURL = async () => {
      try {
        const { data } = await axios.get(
          `/api/documents/${document.id}/pre_signed_url`,
          {
            params: { filepath: document.file.path }
          }
        )
        setDocumentSignedURL(data.path)
      } catch (err) {
        console.log(err)
      }
    }
    getDocumentSignedURL()
  }, [document])

  const productPath = reference.lot
    ? `/products/${reference.product_id}?reference=${reference.name}&lot=${reference.lot}`
    : `/products/${reference.product_id}?reference=${reference.name}`

  const breadcrumbs = [
    { path: '/', name: t('breadcrumbs.home'), active: false },
    {
      path: productPath,
      name: reference.name,
      active: false
    },
    {
      path: `/document/${document.id}/${versionId}`,
      name: t('breadcrumbs.document'),
      active: true
    }
  ]

  return (
    <div className='document-wrapper flex-grow-1'>
      {haveNotifications && (
        <Notification
          notifications={document.notifications}
          show={notificationVisible}
          onHide={hideNotification}
        />
      )}

      <Container className='document-container' data-testid='document-section'>
        <Row>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Row>
        <Row className='mb-2'>
          <Col lg='9' className='d-none d-lg-block'>
            <DocumentViewer
              document={document}
              documentSignedURL={documentSignedURL}
            />
          </Col>
          <Col md='12' lg='3'>
            <Card className='connect-card'>
              <Card.Body>
                <DocumentActions
                  document={document}
                  reference={reference}
                  versionId={versionId}
                  showNotification={showNotification}
                  documentSignedURL={documentSignedURL}
                />
              </Card.Body>
            </Card>
            {subscribe && (
              <Card className='connect-card mt-4'>
                <Card.Body>
                  <DocumentSubscription
                    documentId={document.id}
                    productId={reference.product_id}
                  />
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

DocumentSection.propTypes = propTypes

export default withTranslation()(DocumentSection)
