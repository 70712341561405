import React, { useState } from 'react'
import { Container, Col, Row, Alert } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import Breadcrumbs from './../Breadcrumbs'
import OrderDetail from './OrderDetail'
import OrderFormContainer from './OrderFormContainer'

const propTypes = {
  reference: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  versionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  t: PropTypes.func.isRequired
}

const OrderSection = ({ reference, document, product, versionId, t }) => {
  const [confirmed, setConfirmed] = useState(false)
  const [error, setError] = useState(false)

  function showConfirmation() {
    setConfirmed(true)
    setTimeout(() => setConfirmed(false), 5000)
  }

  function showError() {
    setError(true)
    setTimeout(() => setError(false), 5000)
  }

  const productPath = reference.lot
        ? `/products/${reference.product_id}?reference=${reference.name}&lot=${reference.lot}`
        : `/products/${reference.product_id}?reference=${reference.name}`

  const breadcrumbs = [
    { path: '/', name: t('breadcrumbs.home'), active: false },
    {
      path: productPath,
      name: reference.name,
      active: false
    },
    {
      path: `/order/${document.id}/${versionId}`,
      name: t('breadcrumbs.document_request'),
      active: true
    }
  ]

  return (
    <Container data-testid='order-section' className='flex-grow-1 mb-4'>
      {confirmed && (
        <div className='msg-success'>
          <span>{t('order_section.success')}</span>
          <button type='button' onClick={() => setConfirmed(false)}>
            <i className='close-icon' />
          </button>
        </div>
      )}
      {error && (
        <Alert
          variant='danger'
          className='mt-2'
          onClose={() => setError(false)}
          dismissible
        >
          {t('order_section.failure')}
        </Alert>
      )}
      <Row>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Col lg={6} md={6}>
          <OrderDetail
            reference={reference}
            product={product}
            document={document}
          />
        </Col>
        <Col lg={6} md={6}>
          <OrderFormContainer
            product={product}
            reference={reference}
            document={document}
            versionId={versionId}
            showConfirmation={showConfirmation}
            showError={showError}
          />
        </Col>
      </Row>
    </Container>
  )
}

OrderSection.propTypes = propTypes

export default withTranslation()(OrderSection)
