import '../components/browserUpdate'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import mixpanel from 'mixpanel-browser'
import { MixpanelProvider } from 'react-mixpanel'
import { Provider as StoreProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import * as Sentry from '@sentry/browser'

import 'typeface-lato'
import 'typeface-roboto-mono'

// import { persistor, store } from 'redux/store'
import { store, persistor } from 'redux/configureStore'
// import configureStore from 'redux/configureStore'

import ConnectApp from '../components/connect/ConnectApp'
import countries from 'i18n-iso-countries'
import locales from 'components/connect/helpers/Locales'

_.forEach(locales, (locale) =>
  countries.registerLocale(require(`components/connect/helpers/i18n/countries/${locale}.json`))
)

mixpanel.init('8a22005b42853b5db8ba677121a5048c', {
  persistence: 'localStorage'
})

const { SENTRY_DSN_REACT, HEROKU_ENV } = process.env
if (!HEROKU_ENV !== 'development') {
  // console.log({ SENTRY_DSN_REACT, HEROKU_ENV })
  Sentry.init({ dsn: SENTRY_DSN_REACT, environment: HEROKU_ENV })
}

document.addEventListener('DOMContentLoaded', () => {
  const div = document.createElement('div')
  div.setAttribute('class', 'vh-100')
  ReactDOM.render(
    <MixpanelProvider mixpanel={mixpanel}>
      <StoreProvider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <ConnectApp />
        </PersistGate>
      </StoreProvider>
    </MixpanelProvider>,
    document.body.appendChild(div)
  )
})
